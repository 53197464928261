<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title
          style="
            background-color: #ffffff;
            color: #2a409a;
            font-size: 20px;
            font-weight: 500;
          "
          > {{$t("Confirmation")}}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <br />
          <div
            style="
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              color: #000000;
            "
          > 
           {{noRecordText}}
          </div>
        </v-card-text>
        <v-divider />

        <v-card-actions style="display:flex; justify-content:right;">
          <div style="margin-right:20px;">
            <v-btn
              outlined
              style="background-color:#fffff; color: rgb(42, 64, 154); border:1px solid rgb(42, 64, 154); width:90px;"
              @click="clickNo()"
              > OK  </v-btn
            >
          </div> 
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
    props : ["noRecordText"],
    data() {
        return {
            dialog: true,
        };
    },
    methods: {
        clickNo() {
            this.dialog = false; 
            this.$emit("closeCancelOperation", false);
        },
    },
};
</script>
