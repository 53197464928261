var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"overflow-x":"hidden"},attrs:{"persistent":"","scrollable":"","width":"50%"},model:{value:(_vm.slaDialog),callback:function ($$v) {_vm.slaDialog=$$v},expression:"slaDialog"}},[_c('div',{staticStyle:{"color":"white"}},[_c('v-card',[_c('div',{staticStyle:{"display":"flex","height":"54.3vh","justify-content":"space-between","flex-direction":"column"}},[_c('v-card-title',{staticClass:"title-box"},[_c('v-row',{staticStyle:{"border-bottom":"2px solid gray","background-color":"white"},attrs:{"dense":""}},[(_vm.breachKey === 'overall_breach_status')?_c('v-col',{attrs:{"cols":"6"}},[_vm._v("Overall SLA Breach Status")]):_c('v-col',{attrs:{"cols":"6"}},[_vm._v("Stage SLA Breach Status")]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6"}},[_c('v-icon',{staticClass:"icon",on:{"click":_vm.close}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticStyle:{"display":"flex","height":"54.3vh","justify-content":"space-between","flex-direction":"column","margin-top":"52px","overflow-y":"scroll"},attrs:{"id":"items"}},[(_vm.breachKey === 'overall_breach_status')?_c('table',{staticClass:"table"},[_c('thead',{attrs:{"id":"fixed-header"}},[_c('tr',_vm._l((_vm.overallSlaLevels),function(level){return _c('th',{key:level},[_vm._v(_vm._s(level['displayName']['en']))])}),0)]),_c('tbody',[_c('tr',{attrs:{"id":"body-row"}},_vm._l((_vm.overallSlaLevels),function(value,key){return _c('td',{key:key,staticStyle:{"text-align":"center"}},[_c('v-icon',{style:([
                      _vm.slaBreachData[value.levelKey] == true ? { color: 'red' } : { color: 'green' } ]),attrs:{"dense":""}},[_vm._v("mdi-brightness-1")]),(_vm.slaBreachData[value.levelKey])?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"transition":"fade-transition"}},'span',attrs,false),on),[_vm._v(" Breached ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value.displayName[_vm.$i18n.locale]))])])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"transition":"fade-transition"}},'span',attrs,false),on),[_vm._v(" Not Breached ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value.displayName[_vm.$i18n.locale]))])])],1)],1)}),0)])]):_c('table',{staticClass:"table"},[_c('thead',{attrs:{"id":"fixed-header"}},[_c('tr',[_c('th',{staticStyle:{"text-align":"left"}},[_vm._v("Stage")]),_vm._l((_vm.maxStageSlaLevels),function(index){return _c('th',{key:index},[_vm._v("Level "+_vm._s(index))])})],2)]),_c('tbody',_vm._l((_vm.stageSlaLevels),function(value,key){return _c('tr',{key:key,attrs:{"id":"body-row"}},[(_vm.slaBreachData[key])?[_c('td',{staticStyle:{"text-align":"left","font-weight":"500"}},[_vm._v(" "+_vm._s(key)+" ")]),_vm._l((value),function(value,index){return _c('td',{key:index,staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"vertical-div"},[_vm._v(_vm._s(value['displayName']['en']))]),_c('div',{staticClass:"vertical-div",staticStyle:{"color":"black"}},[_c('v-icon',{style:([
                      _vm.slaBreachData[key][value.levelKey] == true ? { color: 'red' } : { color: 'green' } ]),attrs:{"dense":""}},[_vm._v("mdi-brightness-1 ")]),(_vm.slaBreachData[key][value.levelKey])?_c('span',[_vm._v(" Breached ")]):_c('span',[_vm._v(" Not Breached ")])],1)])})]:_vm._e()],2)}),0)])])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }