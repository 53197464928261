<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent v-model="dialog" max-width="70%" max-height="100%">
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template> -->
        <v-card>
          <v-card-title
            style="
              border-bottom: 2px solid gray;
              overflow-x: hidden;
              padding-left: 40px;
              padding-right: 40px;
              padding-bottom: 0px;
              padding-top: 5px;
            "
          >
            <div class="title_det_cmpont" style="display: flex">
              <div class="title_det_cmpont_left">
                <v-tabs class="justify-space-between" hide-slider>
                  <v-tab @click="clickTabItem('details')">Details </v-tab>
                  <v-tab key="history" @click="clickTabItem('history')"
                    >History</v-tab>
                  <v-tab v-if="showRecent" @click="clickTabItem('recentAssignee')">{{tabDisplayName}} </v-tab>
                </v-tabs>
              </div>
              <div class="title_det_cmpont_right">
                <v-icon style="color: red; cursor: pointer" @click="clickClose()"
                  >mdi-close</v-icon
                >
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0">
                  <div 
                    class="scroll-2"
                    v-if="showDetailComponent"
                    style="overflow-y: scroll; height: 64vh; box-shadow: none"
                    >
                    <Details
                      :screenDefinition="screenDefinition"
                      :itemDetails="itemDetails"
                      :viewId="viewId"
                      @closeDetailsDialog="closeDetailsDialog"
                      :selectedScope="selectedScope"
                    ></Details>
                  </div>
                  <div v-if="this.showDetailComponent"
                    style="
                      display: flex;
                      flex-direction: row;
                      float: right;
                      padding-top: 10px;
                    "
                  >
                  <div class="my-2" style="margin: 3px" v-if="enableDownloadDetails && isDownload">
                    <v-btn class="pdfDownload" color="#2A409A" @click="downloadRecordAsPdf()" ><v-icon style="margin-right:4px;">mdi-file-document-outline</v-icon>  Download </v-btn>
                  </div>
                  <template v-if="this.currentScopeKey!=='ALL'">
                    <div
                      class="my-2"
                      style="margin: 3px"
                      v-for="(actionItem, index) in itemDetails.status_action"
                      :key="index"
                    >
                    <v-btn
                      depressed
                      :color="getButtonColor(actionItem)"
                      style="color: white"
                      @click="
                        changeWorkFlow(
                          actionItem,
                          itemDetails
                        )
                      "
                      >{{actionItem.displayText[$i18n.locale]}} </v-btn>
                    </div>
                  </template>
                  </div>
                  <v-card
                    class="scroll-2"
                    v-if="showHistoryComponent"
                    style="overflow-y: scroll; height: 64vh; box-shadow: none"
                  >
                    <template>
                      <div v-for="item in commentsArray">
                        <NewHistoryComponent :item="item" :stageColor="stageColor" :screenDefinition="screenDefinition"> </NewHistoryComponent>
                      </div>
                    </template>
                  </v-card>
                  <div
                    v-if="showHistoryComponent && enableDownloadHistory"
                    style="
                      display: flex;
                      flex-direction: row;
                      float: right;
                      padding-top: 10px;  "
                  >
                    <downloadCSV :data="commentsArray" :fields="fieldsToExport" :name="csvFileName">
                      <v-btn color="#2A409A" style="color: white" @click="changeCsvFileName()">
                        Download
                      </v-btn>
                    </downloadCSV>
                  </div>
				  <div 
                    class="scroll-2"
                    v-if="showRecentAssignee"
                    style="height: 64vh; box-shadow: none"
                    >
					<v-simple-table>
						<template v-slot:default>
						<thead>
							<tr>
							<th class="text-left">
								Stage
							</th>
							<th class="text-left">
								Assigne
							</th>
							</tr>
						</thead>
						<tbody>
							<tr
							v-for="item in dataRecentItems"
							:key="item.stage"
							>
							<td>{{ item.stage }}</td>
							<td>{{ item.assignee[0].userId }}</td>
							</tr>
						</tbody>
						</template>
					</v-simple-table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <div v-if="showWorkFlowFieldDialog">
      <WorkFlowFieldDialog
        :fieldObject="fieldObject"
        :showIndependentFields="showIndependentFields"
        :screenDefinition="screenDetails"
        :itemDetails="itemDetails"
        @closeWorkFlowDialog="closeWorkFlowDialog"
        :selectedScope="currentScopeKey"
      ></WorkFlowFieldDialog>
    </div>
  </div>
</template>

<script>
import Details from "./Details.vue";
import NewHistoryComponent from "./NewHistoryComponent.vue";
import WorkFlowFieldDialog from "./workFlowFieldDialog.vue";
import downloadCSV from "vue-json-csv";
import moment from "moment";
import { jsPDF } from "jspdf";
import { currencyFormat } from "../mixins/currencyFormat.js";
import autoTable from 'jspdf-autotable';

export default {
  props: ["itemDetails", "screenDefinition", "showIndependentFields", "viewId","selectedScope","latestAssignee","latestAssigneeFlag","applicationName","isDownload","workFlowItems"],
  components: {
    WorkFlowFieldDialog,
    Details,
    NewHistoryComponent,
    downloadCSV,
  },
  mixins: [currencyFormat],
  data() {
    return {
      dialog: true,
      formatConfig : {
        maximumFractionDigits: 20
      },
      enableDownloadDetails : false,
      enableDownloadHistory: false,
      screenDetails: "",
      showWorkFlowFieldDialog: false,
      fieldObject: {},
      status_action: "",
      currentScopeKey:"",
      showDetailComponent: true,
      showHistoryComponent: false,
      showRecentAssignee: false,
      dataRecentItems:[],
      showRecent:false,
      tabDisplayName:'',
      commentsArray: [],
      dataItem: "",
      fieldsToExport: ["dateTime","FromStage","ToStage","Assigned To", "text", "userId","userGroup","Comments"],
      stageColor:{},
      csvFileName: `Record ${new Date().toLocaleDateString()}-history.csv`
    };
  },
  mounted() {
    if(this.$props.latestAssignee && this.$props.latestAssignee.enabledStages && Object.keys(this.$props.latestAssignee.enabledStages).length > 0 &&  this.$props.latestAssigneeFlag === true){
      for (var id in this.$props.latestAssignee.enabledStages){
        if(this.$props.itemDetails.workflow_id == id ){
          this.tabDisplayName = this.$props.latestAssignee.tabDisplayName;
          this.showRecent = true;
          for (var stage in this.$props.latestAssignee.enabledStages[id]){
            if(this.$props.itemDetails.RecentAssignee[this.$props.latestAssignee.enabledStages[id][stage]]){
              this.dataRecentItems.push({
                  stage:this.$props.latestAssignee.enabledStages[id][stage],
                  assignee:this.$props.itemDetails.RecentAssignee[this.$props.latestAssignee.enabledStages[id][stage]]
              })
            }
          }
        }
      }
    }

    this.dataItem = this.$props.itemDetails;
    this.$props.itemDetails.workFlowItems = this.$props.workFlowItems;
    this.status_action = this.dataItem["status_action"];
    this.currentScopeKey=this.$props.selectedScope;
    this.screenDetails = this.$props.screenDefinition;
    if(this.screenDetails.enableDownloadEntityHistory) {
      this.enableDownloadHistory = this.screenDetails.enableDownloadEntityHistory;
    }
    if(this.screenDetails.enableDownloadEntityDetails) {
      this.enableDownloadDetails = this.screenDetails.enableDownloadEntityDetails;
    }
    if(this.screenDetails.fieldDetails.hasOwnProperty('fields')){
      this.screenDetails.fieldDetails.fields.forEach(element => {
        if(element.hasOwnProperty('color')){
          this.stageColor = element.color
        }
      })
    }
    this.commentsArray = this.dataItem.Comments;
    for(var comment in this.commentsArray){
      this.commentsArray[comment]['dateTime'] = moment(this.commentsArray[comment]['dateTime']).format('YYYY-MM-DD HH:mm:ss');
      if(this.commentsArray[comment]['status']){
      this.commentsArray[comment]['FromStage']=this.commentsArray[comment]['status']['prevStatus']
      this.commentsArray[comment]['ToStage']=this.commentsArray[comment]['status']['currStatus']
      this.commentsArray[comment]['MakerChecker']=this.commentsArray[comment]['MakerChecker']
      };
    }
  },
  methods: {
    inputTypeChecks(fieldItem){
      let checklistArray = [];
      let optionData = '';
      if(!(this.itemDetails[fieldItem.key])) {
      return ' ';
      }
      if(fieldItem.input_type === 'options' && fieldItem.view_type === 'reference'){
        fieldItem.option_values.map((optionValue)=> {
            if(optionValue.key === this.itemDetails[fieldItem.key]){
              optionData =  optionValue.value;
            }})
        return optionData
      } else if (fieldItem.input_type === 'hierarchicalDropdown' && fieldItem.view_type === 'reference'){
            return this.itemDetails[fieldItem.key+"_reference"];
      } else if(fieldItem.input_type === 'number') {
        let numberData = this.numberFormatter(fieldItem, this.itemDetails[fieldItem.key]);
        return numberData;
      } else if(fieldItem.input_type === 'checklist'){
        this.itemDetails[fieldItem.key].map((element)=>{
        if(element.checked){
          checklistArray.push(element.value);
        }
        });
        return checklistArray;
      } else if (fieldItem.input_type === 'currency') {
        let currencyData = this.formatCurrency(this.itemDetails[fieldItem.key],fieldItem)
        return currencyData;
      } else if (fieldItem.input_type === 'priority') {
        let currencyData = this.formatPriority(this.itemDetails[fieldItem.key],fieldItem)
        return currencyData;
      } else if (fieldItem.input_type === 'alert') {
        let alertDate = this.formateAlertDate(this.itemDetails[fieldItem.key])
        return alertDate;
      } else if (fieldItem.key === 'consolidated_overall_breach_status' || fieldItem.key === 'consolidated_stage_breach_status') {
        if( this.itemDetails[fieldItem.key] == true) {
          return 'Breached';
        } else {
          return 'Not Breached';
        }
      }
      //others       
      return this.itemDetails[fieldItem.key]
    },
    changeCsvFileName(){
      let {recordId} = this.arrayOfContents();
      this.csvFileName =`Record ${recordId}-History.csv`
    },
    arrayOfContents(){
    let dataArr = [];
    let recordata;
    let recordId;
    let unwantedKey=["status_action","_id","CreatedAt","UpdatedAt", 'stage_breach_status', 'overall_breach_status'];
    let unwantedIp= ["fileupload","multifileupload"];
    let fieldDef = this.screenDetails.fieldDetails.fields;
    for(let fieldItem of fieldDef){
      if(fieldItem.key === "record_id"){
        recordId = this.itemDetails[fieldItem.key]
      }
      if(unwantedKey.includes(fieldItem.key) || unwantedIp.includes(fieldItem.input_type)) {
        continue;
      }else if(!fieldItem.isDependent){
        //option values
        recordata = this.inputTypeChecks(fieldItem);
        dataArr.push([fieldItem.display_name[this.$i18n.locale],recordata])
      }else if (((fieldItem.dependentValue.includes(this.itemDetails[fieldItem.dependentField]) &&
                this.itemDetails[fieldItem.key] != '') ||
                this.itemDetails[fieldItem.key] != '') && fieldItem.isVisible) {
        recordata = this.inputTypeChecks(fieldItem);
        dataArr.push([fieldItem.display_name[this.$i18n.locale],recordata])
      }
    };
    return  {dataArr , recordId }
    },
    downloadRecordAsPdf(){
      var doc = new jsPDF();
      const head = [['Name', 'Details']]
      //APP NAME WITH LOCALE
      let appName = this.applicationName[this.$i18n.locale]
      let { dataArr , recordId} = this.arrayOfContents();
      autoTable(doc, {
      head: head,
      body: dataArr,
      bodyStyles:{lineWidth:.3, lineColor: "#2A409A"},
      headStyles:{halign:"center" ,fillColor:"#2A409A",lineWidth:.3, lineColor: "#2A409A"},
      startY: 30,
      didDrawPage: function (dataArr) {
      // Header
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.setFont(undefined, 'bold').text(appName,100, 22,{align:"center"});
      
      //TABLE MARGIN
      dataArr.settings.margin.top = 30;
      // Footer
      var str = "Page " + doc.internal.getNumberOfPages();
      doc.setFont(undefined, 'normal')
      doc.setFontSize(10);
     
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, dataArr.settings.margin.left, pageHeight - 10);
      }
      })
      doc.save(`Record ${recordId}.pdf`);
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    formateAlertDate(date){
      return new Date(date)
    }, 
    formatPriority(value,priorityField){
      let priorityData;
      priorityField.option_values.map((options)=>{
        if(options.key === value){
          priorityData =  options.displayText;
          return options
        }
      })
      return priorityData
    },
    formatCurrency(value,currencyField){
      let currencyDisplay = 'narrowSymbol';
      if(this.itemDetails.AdditionalFieldInformation && this.itemDetails.AdditionalFieldInformation[currencyField.key]){
        var system;
        var digitsAfterdecimalPoint;
        var currencyCodeValue;
        currencyCodeValue = this.itemDetails["AdditionalFieldInformation"][currencyField.key]["currencyCode"];
        if(currencyCodeValue){
          if(currencyField.currency_configuration){
            if(currencyField.currency_configuration.currencyDisplay){
              if(currencyField.currency_configuration.currencyDisplay === 'code'){
                  currencyDisplay = 'code';
                } else if(currency_configuration.currencyDisplay === 'symbolAndCode'){
                  currencyDisplay = 'symbolAndCode';
                }
            }
          }
          if(currencyField.currency_configuration && currencyField.currency_configuration.format[currencyCodeValue]){
            system = currencyField.currency_configuration.format[currencyCodeValue]["system"]
            digitsAfterdecimalPoint = currencyField.currency_configuration.format[currencyCodeValue]["digitsAfterdecimalPoint"];
            return this.formatCurrencyValue(currencyCodeValue,digitsAfterdecimalPoint,value,system, currencyDisplay)
          }
          else {
            return this.formatCurrencyValue(currencyCodeValue,2,value,"English", currencyDisplay)
          }
        }
        else {
          return this.formatCurrencyValue("USD",2,value,"English", currencyDisplay)
        }
      }
      else {
        return this.formatCurrencyValue("USD",2,value,"English", currencyDisplay)
      }
		},
    
    numberFormatter(fieldItem, numberInput) {
      var input = numberInput;
      if(fieldItem.number_configuration && fieldItem.number_configuration.numberSystem){
        if(fieldItem.number_configuration.numberSystem == "English"){
          const NumberFormatter = new Intl.NumberFormat("en-US", this.formatConfig);
          input = NumberFormatter.format(input); 
        }
        else if(fieldItem.number_configuration.numberSystem == "Arabic"){
          const NumberFormatter = new Intl.NumberFormat("en-AR", this.formatConfig);
          input = NumberFormatter.format(input); 
        }
      }
      return input
    },
    getButtonColor(actionItem) {
      if (actionItem.hasOwnProperty("color")) {
        return actionItem.color;
      } else {
        return "#2A409A";
      }
    },
    clickTabItem(item) {
      if (item === "details") {
        this.showDetailComponent = true;
        this.showHistoryComponent = false;
        this.showRecentAssignee = false;
      }
      if (item === "history") {
        this.showHistoryComponent = true;
        this.showDetailComponent = false;
        this.showRecentAssignee = false;
      }
      if (item === "recentAssignee") {
        this.showHistoryComponent = false;
        this.showDetailComponent = false;
        this.showRecentAssignee = true;
      }
    },
    clickClose() {
      this.dialog = false;
      this.$emit("closeSidePanel", false);
    },
    closeDetailsDialog(flag){
      this.$emit('closeDetailsComponent',flag);
    },
    closeWorkFlowDialog(flag) {
      this.showWorkFlowFieldDialog = flag;
      this.$emit('closeDetailsComponent',flag);;
    },
    changeWorkFlow(action, item) {
      this.showWorkFlowFieldDialog = true;
      this.fieldObject["assigneeGroup"] = action.defaultAssigneeGroup;
      this.fieldObject["display"] = true;
      this.fieldObject["viewId"] = this.$props.viewId;
      this.fieldObject["action"] = "Workflow";
      this.itemDetails = this.dataItem;
      this.fieldObject["next_status"] = action.key;
      this.fieldObject["previousAssignee"] = [];
      this.fieldObject["persistAssignee"] = action.persistAssignee;
      this.fieldObject["selfAssign"] = {};
      if (action.assignToCreator && (action.assignToCreator === true || (typeof action.assignToCreator === 'object' && Object.keys(action.assignToCreator).length>0))) {
        this.fieldObject["assignToCreator"] = action.assignToCreator;
        if(item.RecentAssignee && item.RecentAssignee.default && item.RecentAssignee.default[0].roleGroup) {
          this.fieldObject["assigneeGroup"] = item.RecentAssignee.default[0].roleGroup;
        }
      }
      for (var statusIndex in item.status_action) {
        if (item.status_action[statusIndex].key === action.key) {
          if (
            item.status_action[statusIndex].hasOwnProperty(
              "returnToPreviousAssignee"
            )
          ) {
            if (
              item.status_action[
                statusIndex
              ].returnToPreviousAssignee.hasOwnProperty("stage")
            ) {
              var previousStage =
                item.status_action[statusIndex]["returnToPreviousAssignee"][
                  "stage"
                ];
              if (item.hasOwnProperty("RecentAssignee")) {
                if (
                  item.RecentAssignee.hasOwnProperty(
                    item.status_action[statusIndex].returnToPreviousAssignee[
                      "stage"
                    ]
                  )
                ) {
                  this.fieldObject["previousAssignee"] = item.RecentAssignee[previousStage];
                  if(item.RecentAssignee && item.RecentAssignee[previousStage] && item.RecentAssignee[previousStage][0].roleGroup) {
                    this.fieldObject["assigneeGroup"] = item.RecentAssignee[previousStage][0].roleGroup;
                  }
                }
              }
            }
          }
        }
        if (item.status_action[statusIndex].hasOwnProperty("selfAssign")) {
          this.fieldObject["selfAssign"] = item.status_action[statusIndex];
        }
      }
    },
  },
};
</script>

<style scoped>
.items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.items_sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.items_sec h5 {
  font-size: 0.7rem;
  padding: 3px;
}

.title_det_cmpont {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  float: left;
  width: 100%;
}

/* .scroll::-webkit-scrollbar {
      width: 5px;
      background-color:gray;
      scrollbar-color: #ebe7e7;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	    border-radius: 10px;
	    background-color: #F5F5F5;
    } */

.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}

.v-tab--active {
  background-color: rgb(42, 64, 154);
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 0.8rem;
}

.v-tab.v-tab {
  color: white;
}
.pdfDownload{
  color: white; 
  padding:10px; 
  justify-content: flex-end;
}
</style>