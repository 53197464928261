<template>
  <div>
    <div v-if="showMessageDialog ">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <v-dialog persistent scrollable v-model="dialog" content-class="notes-dialog">

    <v-card  style="width: 100%; height:90vh; padding: 0px; overflow: visible; display: flex; flex-direction: column; ">
    
    
      <v-card-title
        style="
          background-color: #ffffff;
          color: #2a409a;
          font-size: 20px;
          font-weight: 500;
          display: flex; flex-direction: row; justify-content: space-between;
        "
        >
        <p style="font-size: 20px;">Comments <br><span style="color: #9798A3; font-size:16px;">All the comments are listed here</span></p>
        <div  style="margin-right:10px">
          <v-btn 
          class="refreshbutton"
          color="#2a409a"
            @click="getAllNotes()"
            > Refresh <v-icon>mdi-refresh </v-icon></v-btn
          > <v-icon outlined
            style="
              background-color: #fffff;
              color: red;
              font-size:30px;
            "
            @click="clickCancel"> mdi-close-circle </v-icon>
        </div>
        </v-card-title
      >
    
      <v-divider />
    
    
    
      <v-card-text
        class="scroll-2 dialogWrapper flex-grow-0" 
        style="overflow-y: scroll; overflow-x: hidden;"
        ref="parentDiv"
      >
      <v-card-actions >
        <v-spacer></v-spacer>
      <div  class="textEditorGroup"  v-if="shouldShowCommentBox" >
        <div class="editorBtnsWrapper"> 
            <v-icon class="editoractionIcon" @click="editor.chain().toggleBold().run()">mdi-format-bold</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().toggleItalic().run()">mdi-format-italic</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().toggleUnderline().run()">mdi-format-underline</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().setTextAlign('left').run()">mdi-format-align-left</v-icon>
            <v-icon class="editoractionIcon" @click="editor.chain().setTextAlign('center').run()">mdi-format-align-center</v-icon>
            <v-icon class="editoractionIcon" @click="editor.chain().setTextAlign('right').run()">mdi-format-align-right</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().undo().run()" :disabled="canAllowUndo" >mdi-undo</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().redo().run()" :disabled="canAllowRedo" >mdi-redo</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().toggleOrderedList().run()">mdi-format-list-numbered</v-icon> 
            <v-icon class="editoractionIcon" @click="editor.chain().toggleBulletList().run()" >mdi-format-list-bulleted </v-icon> 
        </div>
        <editor-content class="ProseMirror" :editor="editor" placeholder="Type in your comment here...." />
        <div class="commentBtnGroup"> 
          <v-btn class="commentBtn" :disabled="isEditorEmpty" color="#2a409a" @click="addANote()">Comment</v-btn>
        </div>
      </div>
      </v-card-actions>
      
      <p v-if="!showSpinner && notesOnRecord.length>0" style="margin-left:16px; font-size: 18px;   color: #2a409a;"> Recently Added Comments:</p>
      <p v-if="noCommentsPresent" style="text-align: center; font-size: 18px;   color: #2a409a;"> No comments are added yet</p>

      <SpinnerComponent v-if="showSpinner"></SpinnerComponent>
      <template v-else>
        <div  v-for="(note,index) in notesOnRecord" :key="index">
            <v-card-text
            class="scroll-2 dialogWrapper flex-grow-0" 
            >
              <div class="messageHeader">
                <p style="font-weight: 600; font-size: 14px;">{{note['userDetails']['displayName']}} <br> 
                  <span style="font-size: 14px; font-weight: 400; line-height: 21px;"> {{ note['createdBy']  }}</span></p>
                  <p style="padding-top: 5px;">{{moment(new Date(note['createdAt'])).format('MMMM Do YYYY, h:mm:ss a')}}</p>
              </div>
              <div class="messageBody" v-html="note['noteDetails']" >
              </div>
              <v-divider style="margin-top:26px;"/>
          </v-card-text>
          
      </div>
      <div class="loadMoreBtn" v-if="isMoreNotesAvailable"> 
            <v-btn 
            depressed
          class="refreshbutton"
          color="#2a409a"
            @click="getMoreNotes()"
            > Load More... </v-btn> 
          </div>
      </template>        
      </v-card-text>
      <div id="dummy">

      </div>
    </v-card>

   
    <v-dialog width="700px" content-class="custom-dialog" transition="dialog-top-transition" v-if="commentAddSuccess" v-model="commentAddSuccess" >
      <v-card style="max-width: 100%; height:105px; padding: 0px; overflow-y: hidden; display: flex; flex-direction: column; border-radius: 5px; ">
        <v-card-title
        style="
          background-color: #ffffff;
          color: #2a409a;
          font-size: 20px;
          font-weight: 500;
          display: flex; flex-direction: row; justify-content: space-between; align-items:center;
        "
        >
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <div>
            <img style="height: 70px; width: 70px;" src="../assets/CommentAddedIcon.svg" />
          </div>
          <div style="margin-left:20px; margin-top: 5px;"> 
            <p style="font-size: 20px;"> New comment has been added <br><span style="color: #9798A3; font-size:16px;">{{ notesDialogProps['userId'] }} added a new comment</span></p>
          </div>
        </div> 
        
        <div  style="margin-right:3px; margin-bottom:15px">
          <v-icon outlined
            style="
              font-size:40px;
              color: #ADB6BC;
            "
            @click="closeCommentAddedDialog()"> mdi-close </v-icon>
        </div>
        </v-card-title>
    </v-card>
      </v-dialog>
  </v-dialog>
  </div>
  
  
</template>

<script>
import axios from "axios";
import * as DOMPurify from 'dompurify';
import SpinnerComponent from "./SpinnerComponent.vue"
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import { getFromLocalStorage } from '../store/localStorageInterface';
import MessageDialog from './MessageDialog.vue';
export default {
  props: [
    'notesDialogProps',
    'viewType'
  ],
  components: {
      SpinnerComponent,
      EditorContent,  
      MessageDialog
  },
  mixins: [],
  data() {
    return {
      editor: null, 
      dialog: true,
      showSpinner:true,
      showMessageDialog: false,
      errorMessageShow:false,
      errorMessageObject:{},
      dialogWidth: '70%',
      notesOnRecord:[],
      LastEvaluatedKey: {},
      isMoreNotesAvailable: false,
      noCommentsPresent: true,
      commentAddSuccess: false,
      isEditorEmpty:true,
    };
  },
  watch: {
  },
  computed: {
    // for displaying undo redo btn
    canAllowRedo() {
      if (this.editor && !this.editor.can().redo()) {
        return true;
      }
      return false;
    },
    shouldShowCommentBox() {
      return this.viewType !== "archive";
    },
    canAllowUndo() {
      if (this.editor && !this.editor.can().undo()) {
        return true;
      }
      return false;
    }
  },
  methods: {
    // closing success popup dialog
    closeCommentAddedDialog() {
      this.commentAddSuccess = false;
    },
    // closing error popup
    closeMessageDialog(ComponentResponse) {
      this.showMessageDialog = ComponentResponse.flag;
    },
    // closing main notes dialog
    clickCancel() {
        this.dialog = false;
        this.$emit("closeNotesDialog", false);
    },
    // get all notes on record
    async getAllNotes() {
      this.showSpinner=true;
      let apiParams = {
          "applicationId": this.$props.notesDialogProps.applicationId,
          "recordId": this.$props.notesDialogProps.recordId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
      }
      await axios.post(process.env.VUE_APP_BACKEND_URL + "/getAllNotesOnRecord", apiParams)
        .then((notesData) => {
            if(notesData.status === 200) {
                this.showSpinner = false;
                for(let noteContent of notesData.data.notesData) {
                    noteContent['noteDetails'] = DOMPurify.sanitize(noteContent['noteDetails']);
                  };
                this.notesOnRecord = notesData.data.notesData;
                if(notesData.data.notesData.length > 0) {
                  this.noCommentsPresent = false;
                }
                if ('LastEvaluatedKey' in notesData.data) {
                  this.isMoreNotesAvailable = true;
                  this.LastEvaluatedKey = notesData.data['LastEvaluatedKey'];
                } else {
                  this.isMoreNotesAvailable = false;
                  this.LastEvaluatedKey = '';
                }
            }
        })
    .catch((errorResponse) => {
          this.showSpinner = false;
          if (errorResponse.response && errorResponse.response.status && errorResponse.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.errorObject) {
              if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.error){
                  this.errorMessage = errorResponse.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: errorResponse.response.data.errorObject.error_code,
                error_uuid: errorResponse.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
      },
    // adding a new note
    async addANote() {
      this.showSpinner = true;
      this.noCommentsPresent = false;
        let apiParams = {
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          "applicationId": this.$props.notesDialogProps.applicationId,
          "recordId": this.$props.notesDialogProps.recordId,
          "userId":  this.$props.notesDialogProps.userId,
          "noteDetails": DOMPurify.sanitize(this.editor.getHTML())
        }
        this.editor.commands.setContent('')
      await axios.post(process.env.VUE_APP_BACKEND_URL + "/addANoteOnRecord", apiParams)
        .then((notesData) => {
          this.showSpinner = false;
            if(notesData.status === 200) {
                this.closeCommentAddedDialog();
                this.notesOnRecord = [notesData.data, ...this.notesOnRecord]
                this.isEditorEmpty = true;
                this.commentAddSuccess= true;
                let successPopupTout= setTimeout(()=>{
                  this.commentAddSuccess = false;
                  clearTimeout(successPopupTout);
                }, 2200);
            }
          })
        .catch((errorResponse) => {
          this.showSpinner = false;
          this.isEditorEmpty = true;
          if (errorResponse.response && errorResponse.response.status && errorResponse.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.errorObject) {
              if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.error){
                  this.errorMessage = errorResponse.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: errorResponse.response.data.errorObject.error_code,
                error_uuid: errorResponse.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
          });
      },
      // with last evaluated key
    async getMoreNotes() {
        let apiParams = {
          "applicationId": this.$props.notesDialogProps.applicationId,
          "recordId": this.$props.notesDialogProps.recordId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          'lastEvalKey' : this.LastEvaluatedKey
        }
      await axios.post(process.env.VUE_APP_BACKEND_URL + "/getAllNotesOnRecord", apiParams)
        .then((notesData) => {
            if(notesData.status === 200) {
                this.showSpinner = false;
                if (notesData.data.notesData.length != 0) {
                  for(let noteContent of notesData.data.notesData) {
                    noteContent['noteDetails'] = DOMPurify.sanitize(noteContent['noteDetails']);
                  };
                  this.notesOnRecord = [...this.notesOnRecord, ...notesData.data.notesData];
                  if ('LastEvaluatedKey' in notesData.data) {
                    this.isMoreNotesAvailable = true;
                    this.LastEvaluatedKey = notesData.data['LastEvaluatedKey'];
                  } else {
                    this.isMoreNotesAvailable = false;
                    this.LastEvaluatedKey = '';
                  }
                } else {
                  this.isMoreNotesAvailable = false;
                  this.LastEvaluatedKey = '';
                }
              this.$nextTick(() => {
                const parentDiv = this.$refs.parentDiv;
                  if (parentDiv) {
                    parentDiv.scrollTop = parentDiv.scrollHeight;
                  }
                });
          }
    })
    .catch((errorResponse) => {
          this.showSpinner = false;
          if (errorResponse.response && errorResponse.response.status && errorResponse.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.errorObject) {
              if(errorResponse.response && errorResponse.response.data && errorResponse.response.data.error){
                  this.errorMessage = errorResponse.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: errorResponse.response.data.errorObject.error_code,
                error_uuid: errorResponse.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
          });
      },
  },
  mounted() {
    // setting up for rich text editor
    this.editor = new Editor({
      onUpdate: ({ editor }) => {
        // disable the comment button on no data present
        if(editor.getHTML().trim() == '<p></p>') {
          this.isEditorEmpty = true;
        } else {
          this.isEditorEmpty = false;
        }
      },
      extensions: [
        Underline,
        StarterKit,
        Placeholder.configure({
            placeholder: 'Type in your comment....',

          }),
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
      ], 
      editorProps: {
    attributes: {
      class: 'my-editor-class', // CSS class for the editor container
      style: 'background-color: white; padding:20px 30px; height: 17vh; max-height: 17vh; overflow: auto; ', // Inline styles for the editor container
    },
      },
    });
  // get the notes on the record
    this.getAllNotes();
  },
  beforeDestroy() {
    // destroying the rich text editor component 
    this.editor.destroy();
  },
};
</script>

<style>
/*main dialog*/
.notes-dialog {
  width: 60%;
}
/*texteditor titap focus and the placeholder design*/
.ProseMirror.my-editor-class {
   outline: none !important;
}
.ProseMirror.my-editor-class p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror.my-editor-class {
  overflow-x: hidden;
  overflow-y: scroll;
}
.ProseMirror.my-editor-class::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}
.ProseMirror.my-editor-class::-webkit-scrollbar-track {
  border-radius: 8px;
}
.ProseMirror.my-editor-class::-webkit-scrollbar-thumb {
  background: #adb6bc;
  border-radius: 8px;
}
.ProseMirror.my-editor-class::-webkit-scrollbar-thumb:hover {
  background: #adb6bc;
}



/*All rich text editor icons*/
.editoractionIcon{
  margin-left: 10px;
}
.editorBtnsWrapper {
  padding: 5px 10px;
  background-color: #E8E8EB;
}

/* rich text editor icons, text area, comment btn - total div*/
.textEditorGroup{
  width:100%; 
  max-height: 30vh;
  border: 1px solid #E8E8EB;
  border-radius: 4px;
  margin: 20px 10px;
}
/* comment btn left aligning and its div */
.commentBtnGroup{
  display: flex;
  justify-content: flex-end;
  padding: 10px 25px 20px 0px;
}
.commentBtn {
  color:white !important;
}
/* refresh btn at the top  */
.refreshbutton {
  color: white !important;
  width: 120px;
  margin-right: 20px;
  text-transform: unset !important;
}
/* at bottom of records load more */
.loadMoreBtn{
display:flex;
justify-content: center;
}
.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
/* individual comments style */
.messageHeader{
  display: flex;
  justify-content: space-between;
}
.messageBody{
  color: #7C8287;
  font-size: 14px;
  background-color: #F5F7FB;
  padding:10px 15px !important;
  border-radius: 4px;
}
.messageBody p {
  margin-bottom: 0px !important;
}
.messageDivider{
  display: flex;
  justify-content: center;
  
}
/* general style for the list of commments   */ 
.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #2a409a;
  box-shadow: 0;
  background-color: #2a409a;
}

/* added success message */
.custom-dialog {
  margin-bottom: 70vh;
}

</style>