<template>
	<v-row justify="center">
	  <v-dialog
		v-model="dialog"
		scrollable
		persistent
		max-width="350px"
	  >
		<v-card>
		  <v-card-title style="border-bottom: 2px solid gray;color:rgb(42, 64, 154)">Files Uploaded</v-card-title>
		  <v-divider></v-divider>
		  <v-card-text style="height: 300px;">
			<v-list-item
				v-for="(index,file) in files" :key="index"
			>
				<v-list-item-content>
					<v-list-item-title>{{ formatFileName(files[file])}}</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon right @click="viewFile(files[file])">
                          mdi mdi-eye
                    </v-icon>
				</v-list-item-icon>
			</v-list-item>
		  </v-card-text>
		  <v-divider></v-divider>
		  <v-card-actions style="display:flex; justify-content:right;">
			<v-btn
              outlined
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 100px;
              "
              @click="clickClose()"
            >
              Close
            </v-btn>
		  </v-card-actions>
		</v-card>
	  </v-dialog>
	  <div v-if="showMessageDialog">
        <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
      </div>
	</v-row>
  </template>
<script>
import axios from "axios";
import {getFromLocalStorage} from "../store/localStorageInterface"
import { formatFileNameMixin } from "../mixins/formatFileName.js";
import MessageDialog from "./MessageDialog.vue";
export default {
    props: ["multiFileArray","workflow_id","fieldDetails","fileFieldKey"],
    components: {
        MessageDialog
    },
	mixins: [formatFileNameMixin],
	data() {
	return{
		dialog: true,
		files:[],
		workflowId:"",
        showMessageDialog:false,
        errorMessageShow: false,
		errorMessageObject: {},
        okMessageObject: {}
	}
    },
    methods: {
	    formatFileName(file){
	    	var folderUrl=file.split("/").slice(-1);
	    	var [first,...rest]= folderUrl[0].split("_")
	    	var fileName = rest.join('_');
			if(fileName) {
				return this.fileNameFormat(fileName);
			} else if(this.$props.fieldDetails["AdditionalFieldInformation"][this.$props.fileFieldKey] && this.$props.fieldDetails["AdditionalFieldInformation"][this.$props.fileFieldKey][folderUrl]) {
				return  this.fileNameFormat(this.$props.fieldDetails["AdditionalFieldInformation"][this.$props.fileFieldKey][folderUrl]);
			}
	    },
        closeMessageDialog(emitObject) {
			var closeDialogFlag = false;
			if(emitObject && emitObject.errorDisplayMessage){
				closeDialogFlag = emitObject.errorDisplayMessage
			}
			this.showMessageDialog = emitObject.flag;
			this.$emit("closeDialog", closeDialogFlag);
        },
	    clickClose(){
	    	this.$emit("closemultiFileDownloadDialog", false);
	    },
	    viewFile(file_name) {
	    var read_body = {
	    	token: getFromLocalStorage("token"),
	    	authType: getFromLocalStorage("authType"),
	    	applicationId: this.$route.query.applicationId,
	    	userId: this.$route.query.userId,
	    	workflow_id:this.workflowId ,
	    	file_name: file_name,
	    };
	    axios
	    	.post(
	    		process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
	    		read_body
	    	)
	    	.then((readSuccess) => {
	    		window.open(readSuccess.data.data, "_blank");
	    	})
	    	.catch((readError) => {
	    	if (
	    		readError.response &&
	    		readError.response.status &&
	    		readError.response.status == 401
	    	) {
	    		this.$root.$emit('update_session_value', true);
	    	}
	    	else{
	    		this.errorMessageShow = true;
	    		if(readError.response && readError.response.data && readError.response.data.errorObject){
	    		this.errorMessageObject = {
	    			errorMessageShow: this.errorMessageShow,
	    			error_code: readError.response.data.errorObject.error_code,
	    			error_uuid: readError.response.data.errorObject.error_uuid,
	    		};
	    		}
	    		else{
	    		this.errorMessageObject = {
	    			errorMessageShow: this.errorMessageShow,
	    			error_code: "",
	    			error_uuid: "",
	    		};
	    	    }
	    	    this.showMessageDialog = true;
	    	}
	    	});
	    }
	},
	mounted() {
	    this.files = this.$props.multiFileArray;
	    this.workflowId = this.$props.workflow_id;
    }
}
</script>
<style>
.items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.items_sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin: 4px;
}
</style>
