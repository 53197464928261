<template>
  <v-dialog
    v-model="slaDialog"
    style="overflow-x: hidden"
    persistent
    scrollable
    width="50%"
  >
    <div style="color: white">
      <v-card>
        <div
          style="
            display: flex;
            height: 54.3vh;
            justify-content: space-between;
            flex-direction: column;
          "
        >
          <v-card-title class="title-box">
            <v-row
              dense
              style="border-bottom: 2px solid gray; background-color: white"
            >
              <v-col cols="6" v-if="breachKey === 'overall_breach_status'" >Overall SLA Breach Status</v-col>
              <v-col cols="6" v-else>Stage SLA Breach Status</v-col>
              <v-col cols="6" style="text-align: right"
                ><v-icon class="icon" @click="close">mdi-close</v-icon></v-col
              >
            </v-row>
          </v-card-title>
          <v-card-text
            id="items"
            style="
              display: flex;
              height: 54.3vh;
              justify-content: space-between;
              flex-direction: column;
              margin-top: 52px;
              overflow-y: scroll;
            "
          >
            <table class="table" v-if="breachKey === 'overall_breach_status'">
              <thead id="fixed-header">
                <tr>
                  <th v-for="level of overallSlaLevels" :key="level">{{ level['displayName']['en'] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  id="body-row"
                >
                  <td
                  v-for="(value, key) in overallSlaLevels"
                  :key="key"
                    style="text-align: center"
                  >
                    <v-icon
                      dense
                      :style="[
                        slaBreachData[value.levelKey] == true ? { color: 'red' } : { color: 'green' },
                      ]"
                      >mdi-brightness-1</v-icon
                    >
                    <span v-if="slaBreachData[value.levelKey]"> 
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }"  >
                          <span transition="fade-transition"
                            v-bind="attrs"
                            v-on="on" 
                            > Breached 
                        </span>
                        </template>
                        <span >{{ value.displayName[$i18n.locale] }}</span>
                      </v-tooltip>  
                    </span>
                    <span v-else>
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }"  >
                          <span transition="fade-transition"
                            v-bind="attrs"
                            v-on="on" 
                            > Not Breached 
                        </span>
                        </template>
                        <span >{{ value.displayName[$i18n.locale] }}</span>
                      </v-tooltip>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table" v-else>
              <thead id="fixed-header">
                <tr>
                  <th style="text-align: left">Stage</th>
                  <th v-for="index in maxStageSlaLevels" :key="index">Level {{ index }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, key) in stageSlaLevels"
                  :key="key"
                  id="body-row"
                >
                <template  v-if="slaBreachData[key]">
                  <td style="text-align: left; font-weight: 500;">
                    {{ key }}
                  </td>
                  <td
                    v-for="(value, index) in value"
                    :key="index"
                    style="text-align: center"
                  >
                  <div class="vertical-div">{{ value['displayName']['en'] }}</div>
                  <div class="vertical-div" style="color: black;">
                    <v-icon
                      dense
                      :style="[
                        slaBreachData[key][value.levelKey] == true ? { color: 'red' } : { color: 'green' },
                      ]"
                      >mdi-brightness-1
                    </v-icon>
                    <span v-if="slaBreachData[key][value.levelKey]">
                      Breached
                    </span>
                    <span v-else> 
                      Not Breached
                    </span>
                  </div>  
                  </td>
                </template>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import Details from "./Details.vue";
export default {
  props: ["breachData", "displaySlaDialog",'breachKey','itemDetails'],
  components: {
    Details,
  },
  data() {
    return {
      slaDialog: "",
      slaBreachData: {},
      overallSlaLevels:[],
      stageSlaLevels: {},
      maxStageSlaLevels: 0
    };
  },
  mounted() {
    this.slaBreachData = this.breachData;
    
    // removing stages without sla 
    this.slaBreachData = Object.fromEntries(
      Object.entries(this.slaBreachData).filter(([key,value]) => {
        // allowing overall sla content and stages with sla
        if (typeof(value) != "object" || Object.keys(value).length > 0) {
          return value ;
        }
        }));

    this.slaDialog = this.displaySlaDialog;

    this.itemDetails.workFlowItems.forEach((workflowItem)=>{
      if (workflowItem && typeof(workflowItem) === "object" ) {
        // checking for same workflow id
        if(this.itemDetails.workflow_id === workflowItem.workflowId){
          // for overall sla
          if(workflowItem.overallSlaDetails) {
            // sorting based on breach percentage
            this.overallSlaLevels = workflowItem.overallSlaDetails.sort((level1,level2) => {
              return level1.breachPercentage - level2.breachPercentage 
            })
            // overallSlalevels array is used for max overall sla levels
          }
          if(workflowItem.stageSlaDetails){ 
            this.stageSlaLevels = workflowItem.stageSlaDetails;
            // sorting based on breach percentage
            Object.keys(this.stageSlaLevels).forEach((stageLevelData)=>{
              this.stageSlaLevels[stageLevelData].sort((firstLevel,secondLevel)=>{
                return firstLevel.breachPercentage - secondLevel.breachPercentage
              });
              // calculating the maximum levels in stage sla
              if(this.maxStageSlaLevels < this.stageSlaLevels[stageLevelData].length ){
                this.maxStageSlaLevels = this.stageSlaLevels[stageLevelData].length
              }
            });
          }
        }
      }
    });
   
  },
  methods: { 
    close(event) {
      this.slaDialog = false;
      this.$emit("clicked", false);
    },
  }
};
</script>
<style scoped>
.vertical-div {
  display: block;
  text-align: center;
  color: rgb(130, 130, 130);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.title-box {
  position: absolute;
  top: 0;
  background-color: white;
  width: 100%;
  max-height: 50px;
}
thead {
  position: -webkit-sticky;
  position: sticky;
  top: 3px;
  z-index: 2;
  background-color: white;
}
table {
  border-collapse: collapse;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 1px;
}
#sub-header{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
tr {
  border-bottom: 1pt solid rgba(224, 224, 224, 1);
}
td,
th {
  padding: 10px;
  margin-left: 5px;
}
th {
  font-weight: 600;
}
.icon {
  color: red;
  margin-top: 0px;
}

#body-row:last-child {
  border-bottom: 1px solid white;
}
#items::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.v-tooltip__content {
  word-wrap: break-word;
  width: 110px !important;
  padding: 2px !important;
  text-align: center;
  background-color:rgb(87, 87, 87) !important;
  color: white !important;
  font-family: Poppins !important;
}

#items::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}
</style>