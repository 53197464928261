<template>
  <v-card>
    <v-card-text>
      <div v-for="text in item.text">
        <li color="black" align="left">{{ text }}</li>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-icon>mdi-account-circle</v-icon>
      <v-text>{{ commentUserId }}</v-text>
      <v-spacer />
      <v-icon>mdi-clock</v-icon>
      <v-text>{{ commentDateTime }}</v-text>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      commentUserId: "",
      commentDateTime: "",
    };
  },
  mounted() {
    this.commentUserId = this.$props.item.userId.split("@")[0];
    this.commentDateTime = this.$props.item.dateTime.split("GMT")[0];
  },
};
</script>
