var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":_vm.dialogWidth},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{class:_vm.dialogCols,staticStyle:{"max-width":"100%","padding":"0px","overflow":"visible"}},[_c('v-card-title',{staticStyle:{"background-color":"#ffffff","color":"#2a409a","font-size":"20px","font-weight":"500","display":"flex","flex-direction":"column","justify-content":"space-between","align-items":"flex-start"}},[_c('p',[_vm._v("Update Assignee")]),(_vm.assigneeDisabled)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","padding":"16px 10px","gap":"16px","position":"relative","width":"100%","height":"56px","background":"#fff5eb","border":"1px solid rgba(255, 165, 82, 0.6)","border-radius":"4px"}},[_c('img',{attrs:{"src":require("../assets/alertOrange.svg")}}),_c('p',{staticStyle:{"font-size":"15px","color":"#4F4F4F","margin-top":"20px"}},[_vm._v("Alert: The assignee can not be changed.")])]):_vm._e()]),_c('v-divider'),_c('v-card-text',{staticClass:"scroll-2 dialogWrapper flex-grow-0 pa-4",staticStyle:{"overflow-y":"scroll","overflow-x":"hidden"}},[_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.updateFieldDialogObject.viewType == 'workflow'),expression:"updateFieldDialogObject.viewType == 'workflow'"}]},_vm._l((_vm.fieldDefinition),function(fieldItem){return _c('div',{staticClass:"flexColumn"},[(
                fieldItem.isVisible &&
                fieldItem.input_type === 'AssigneeUseroptions'
              )?_c('div',{staticClass:"input_field_div"},[_c('div',{staticStyle:{"width":"100%","margin-bottom":"8px"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale])+" "),(
                      fieldItem.validators && fieldItem.validators.required
                    )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_c('span',[(fieldItem.helpTooltipText)?_c('HelpToolTip',{attrs:{"ToolMessage":fieldItem.helpTooltipText}}):_vm._e()],1)])]),_c('div',{staticStyle:{"width":"100%"},attrs:{"align":"right"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#F2F2F2","disabled":true,"rules":fieldItem.validators &&
                    fieldItem.validators.required === true
                      ? _vm.rulesForRequired
                      : _vm.rulesForNotRequired},model:{value:(_vm.assignedToUserGroup),callback:function ($$v) {_vm.assignedToUserGroup=$$v},expression:"assignedToUserGroup"}})],1)]):_vm._e(),(
                fieldItem.isVisible &&
                fieldItem.input_type === 'assigneeOptions'
              )?_c('div',{staticClass:"input_field_div"},[_c('div',{staticStyle:{"width":"100%","margin-bottom":"8px"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale])+" "),(
                      fieldItem.validators && fieldItem.validators.required
                    )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_c('span',[(fieldItem.helpTooltipText)?_c('HelpToolTip',{attrs:{"ToolMessage":fieldItem.helpTooltipText}}):_vm._e()],1)])]),_c('div',{staticStyle:{"width":"100%"},attrs:{"align":"right"}},[_c('v-autocomplete',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","disabled":_vm.assigneeDisabled || fieldItem.disable,"background-color":"#F2F2F2","item-text":"name","item-value":"email","items":_vm.assigneeUserList,"rules":fieldItem.validators &&
                    fieldItem.validators.required === true
                      ? _vm.rulesForRequired
                      : _vm.rulesForNotRequired},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"70px","padding":"5px","width":"100%"}},[_c('div',{staticStyle:{"width":"40px","height":"40px","border-radius":"50%","background-color":"rgb(42, 64, 154)","color":"white"}},[_c('p',{staticStyle:{"font-weight":"500","font-size":"1.2rem","padding-top":"5px","text-align":"center"}},[_vm._v(" "+_vm._s(data.item.name.charAt(0))+" ")])]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"justify-content":"space-around","padding-left":"10px"}},[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('div',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(data.item.email)+" ")])])])]}}],null,true),model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e()])}),0)]),(_vm.showConfirmationDialog)?_c('div',[_c('ConfirmationDialog',{attrs:{"confirmationDialogObject":_vm.confirmationDialogObject},on:{"closeDialog":_vm.closeDialog}})],1):_vm._e(),(_vm.showErrorValidationDialog)?_c('div',[_c('ErrorValidationDialog',{attrs:{"displayErrorValidationDialog":_vm.showErrorValidationDialog,"errorValidationDialogObject":_vm.errorValidationDialogObject},on:{"closeErrorValidationDialog":_vm.closeErrorValidationDialog}})],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',{staticStyle:{"display":"flex","justify-content":"right"}},[(_vm.showAssigneeSection)?_c('div'):_vm._e(),_c('v-spacer'),_c('div',{staticStyle:{"margin-right":"30px","margin-left":"30px"}},[_c('v-btn',{staticStyle:{"background-color":"#fffff","color":"rgb(42, 64, 154)","border":"1px solid rgb(42, 64, 154)","width":"100px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.clickCancel()}}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1),(
          _vm.showAssigneeSection || _vm.updateFieldDialogObject.viewType == 'master'
        )?_c('div',[_c('v-btn',{staticStyle:{"background-color":"rgb(42, 64, 154)","color":"#ffffff","width":"100px"},attrs:{"disabled":_vm.assigneeDisabled},on:{"click":function($event){return _vm.clickSave()}}},[_vm._v(_vm._s(_vm.$t("Save")))])],1):_vm._e()],1)],1),(_vm.showMessageDialog)?_c('div',[_c('MessageDialog',{attrs:{"displayDialog":_vm.showMessageDialog,"messageObject":_vm.errorMessageShow ? _vm.errorMessageObject : _vm.okMessageObject},on:{"closeMessageDialog":_vm.closeMessageDialog}})],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.showSpinner}},[_c('SpinnerComponent')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }