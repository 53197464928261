<template>
  <div>
    <div v-if="showSpinner">
      <v-overlay color="transparent">
        <SpinnerComponent></SpinnerComponent>
      </v-overlay>
    </div>
    <div v-else>
      <div style="background-color:#F3F3F9; ">
        <div class="mainSectionUserList">
          <div class="leftUserGroup">
            <div style="background-color:white; border-radius:5px; padding:20px;">
               <v-tabs
                  :vertical="$vuetify.breakpoint.md ? false : true"
                  v-model="selectedUsergroup" 
                  style="text-align:left;">
                  <v-tab 
                    class="userRoleGroup"
                    v-for="item in userList" 
                    :key="item.Rolegroup"
                    @click="getGroupUserDetails(item.UserList)"> 
                      {{ item.Rolegroup }} 
                  </v-tab>
               </v-tabs>
            </div>
          </div>
          <div class="listMainFrame" v-if="!this.validate" >
            <div class="inrListMainFrame" v-for="user in groupUsers" :key="user.userId" style=""> 
              <div>
                <p  class="userId">
                  <font
                    style="
                      font-size: 1rem;
                      font-weight: 600; 
                    "
                    > {{$t("User ID")}}: &nbsp;
                  </font>
                  {{ user.userId }}
                </p>
              </div>
              <div>
                <p class="userName" style="">
                  <font style="font-size: 1rem; font-weight: 600; text-overflow: ellipsis;"
                    > {{$t("Name")}} : &nbsp;&nbsp;
                  </font>
                   {{ user.userDisplayName }}
                </p>
              </div>
            </div>
          </div>
        <div v-else style="width:100%;display:flex;justify-content:center;padding-top:120px" >
          <h2 style="font-weight:300"> {{$t ('No Users are Available') }}</h2>
        </div>
        </div>
        <div v-if="showDialog">
          <v-dialog v-model="showDialog" width="30%">
            <v-card>
              <v-card-title>{{ $t('Information')}}</v-card-title>
              <v-divider />
              <v-card-text>
                <br />
                {{ $t('User changed successfully')}}
                <br />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="clickOk()">{{$t('Ok')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
export default {
  components: {
    SpinnerComponent,
    MessageDialog,
  },
  data() {
    return {
      userList: "",
      showDialog: false,
      showSpinner: true,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      groupUsers: [],
      selectedUsergroup: null,
      isActive: true,
      validate:false,
    };
  },
  methods: {
    changeUserId(userId) {
      this.showDialog = true;
      setToLocalStorage("userId", userId);
    },
    clickOk() {
      this.showDialog = false;
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    getGroupUserDetails(usersList){
      this.groupUsers= usersList;
      if(this.groupUsers.length==0){
        this.validate=true;
      }
      else{
      this.validate=false;
      }
    }
  },
  mounted() {
    var options = {
      applicationId: this.$route.query.applicationId,
      token: getFromLocalStorage("token"),
      authType: getFromLocalStorage("authType"),
    };
    axios({
      method: "post",
      url: process.env.VUE_APP_BACKEND_URL + "/getAllUserList",
      data: options,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((alluserListResponse) => {
        this.userList = alluserListResponse.data;
        this.selectedUsergroup = alluserListResponse.data[0].Rolegroup;
        this.groupUsers = alluserListResponse.data[0].UserList
        this.showSpinner = false;
      })
      .catch((userListError) => {
        this.showSpinner = false;
        if (userListError.response && userListError.response.status && userListError.response.status == 401) {
          this.$root.$emit('update_session_value', true);
        } else{
          this.errorMessageShow = true;
          if(userListError.response && userListError.response.data && userListError.response.data.errorObject) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: userListError.response.data.errorObject.error_code,
              error_uuid: userListError.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
        
        // this.showMessageDialog = true;
        // this.errorMessageShow = true;
        // this.errorMessageObject = {
        //   errorMessageShow: this.errorMessageShow,
        //   error_code: userListError.response.data.errorObject.error_code,
        //   error_uuid: userListError.response.data.errorObject.error_uuid,
        // };
      });
  },
};
</script>

<style scoped>
.v-tab{
  color:gray;
  text-align:left;
  font-weight:400; 
  border-bottom:1px solid gray; 
  cursor:pointer;   
  padding:10px;
  width:100%;
  background-color:white;
}

.v-tab--active {
  color: rgb(42, 64, 154) !important;
  font-size: 1rem !important;
  background-color: #80808024 !important;
}

.mainSectionUserList{
  width:100%; 
  display:flex; 
  flex-direction:row;
}

.leftUserGroup{
  width:20%; 
  margin-top:5px; 
  padding:2px; 
  height:750px; 
  overflow-y:scroll;  
}

.listMainFrame{
  width:80%; 
  margin:5px; 
  padding:10px; 
  height:750px; 
  border:1px solid white; 
  box-shadow:0px 0px 8px -5px; 
  border-radius:5px;
}

@media screen and (max-width:1500px) {
  .leftUserGroup{
    width:30%; 
    margin-top:5px; 
    padding:2px; 
    height:750px; 
    overflow-y:scroll;  
  }

  .listMainFrame{
    width:70%; 
    margin:5px; 
    padding:10px; 
    height:750px; 
    border:1px solid white; 
    box-shadow:0px 0px 8px -5px; 
    border-radius:5px;
  }
}

@media screen and (max-width:1250px){
  .mainSectionUserList{
    width:100%; 
    display:flex; 
    flex-direction:column;
  }
  .leftUserGroup{
    width:100%; 
    margin-top:5px; 
    padding:2px; 
    height:150px; 
    overflow-y:scroll;  
  }

  .listMainFrame{
    width:100%; 
    margin:5px; 
    padding:10px; 
    height:750px; 
    border:1px solid white; 
    box-shadow:0px 0px 8px -5px; 
    border-radius:5px;
  }
  .v-tab{
    height:90px;
  }
  .v-tab--active {
    height:100px;
    border:1px solid red;
  }
}

.inrListMainFrame{
  width:32%;   
  float:left; 
  margin:2px; 
  padding:7px; 
  border-radius:5px; 
  background-color:white;
  border:1px solid gray;
}

@media screen and (max-width:1500px){
  .inrListMainFrame{
    width:48%;
    margin:2px;   
    float:left; 
    border:1px solid gray;
    padding:5px; 
    border-radius:5px; 
    background-color:white;
  }
}

@media screen and (max-width:1100px){
  .inrListMainFrame{
    width:98%;
    margin:2px;
    float:left; 
    padding:5px; 
    border-radius:5px; 
    background-color:white;
  }
}

.userRoleGroup{ 
  text-overflow: ellipsis;
  overflow: hidden; 
  text-align:left;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  
}

.userId{
  font-weight: 500;  
  overflow: hidden; 
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  white-space: nowrap;
  overflow-wrap: break-word;
  text-align:left;
  -webkit-box-orient: vertical;
}

.userName{
  font-weight: 500;  
  text-overflow: ellipsis;
  overflow: hidden; 
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 13, 223);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 13, 223);
}
</style>
