var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-navigation-drawer',{attrs:{"right":"","fixed":"","width":"75%","color":"#E8EEFF"},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"70%"},attrs:{"align":"left"}},[_c('div',[_c('v-icon',{on:{"click":_vm.clickClose}},[_vm._v(" mdi-close ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","margin-left":"50%"}},_vm._l((_vm.status_action),function(actionItem){return _c('div',{staticStyle:{"margin":"0px 4px"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"depressed":"","color":_vm.getButtonColor(actionItem)},on:{"click":function($event){return _vm.changeWorkFlow(
                    actionItem.key,
                    actionItem.defaultAssigneeGroup,
                    actionItem
                  )}}},[_vm._v(_vm._s(actionItem.displayText[_vm.$i18n.locale]))])],1)}),0)],1),_c('div',{staticClass:"p-2"},[_c('v-card',{staticClass:"scroll",attrs:{"height":"800"}},[_c('v-card-text',[_c('div',{staticClass:"p-2"},_vm._l((_vm.fieldDefinition),function(fieldItem,index){return _c('div',[(!fieldItem.isDependent)?_c('div',[(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'input'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'workflowoptions'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('v-select',{attrs:{"items":fieldItem.option_values,"item-value":"value","item-text":"value","disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1):_vm._e(),(
                    fieldItem.isVisible &&
                    fieldItem.input_type == 'nrc'
                  )?_c('div',{staticClass:"input_field_div"},[_c('div',{staticStyle:{"width":"100%","margin-bottom":"8px"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale])+" "),(
                          fieldItem.validators &&
                          fieldItem.validators.required
                        )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]),_c('div',{staticStyle:{"width":"100%"},attrs:{"align":"right"}},[_c('NRCNumberComponent',{attrs:{"rules":fieldItem.validators &&
                        fieldItem.validators.required === true
                          ? _vm.rulesForRequired
                          : _vm.rulesForNotRequired},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'AssigneeUseroptions'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.assigneeUserGroupOptions},on:{"change":_vm.loadAssigneeUserList},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'apiOptions'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.apiOptionValues},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'assigneeOptions'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.assigneeUserList},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'fileupload'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[(_vm.fields.field_name[fieldItem.key])?_c('div',[_c('img',{class:{ full: _vm.fullWidthImage },attrs:{"src":_vm.fields.field_name[fieldItem.key]},on:{"click":function($event){_vm.fullWidthImage = !_vm.fullWidthImage}}})]):_c('div',[_c('v-file-input',{attrs:{"placeholder":fieldItem.display_name.en,"disabled":_vm.disabled,"prepend-icon":"mdi-paperclip"},on:{"change":function($event){return _vm.fileChange($event, fieldItem.key)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"#007FC4"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)])]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'radio'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-radio-group',{model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}},_vm._l((fieldItem.option_values),function(radioItem){return _c('v-radio',{key:radioItem,attrs:{"disabled":_vm.disabled,"label":radioItem,"value":radioItem}})}),1)],1)])]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'options'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),(fieldItem.view_type === 'reference')?_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"items":fieldItem.option_values,"item-value":"key","item-text":"value","disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1):_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"items":fieldItem.option_values,"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'textarea'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                        fieldItem.isVisible &&
                        fieldItem.input_type === 'phone'
                      )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('VuePhoneNumberInput',{attrs:{"defaultCountryCode":"MM"},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e()]):_c('div',[(
                        (fieldItem.dependentValue.includes(
                          _vm.fields.field_name[fieldItem.dependentField]
                        ) &&
                          _vm.fields.field_name[fieldItem.key] != '') ||
                        _vm.fields.field_name[fieldItem.key] != ''
                      )?_c('div',[(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'input'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-text-field',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'workflowoptions'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('v-select',{attrs:{"items":fieldItem.option_values,"item-value":"value","item-text":"value","disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'AssigneeUseroptions'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.assigneeUserGroupOptions},on:{"change":_vm.loadAssigneeUserList},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'apiOptions'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.apiOptionValues},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'assigneeOptions'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"disabled":_vm.disabled,"items":_vm.assigneeUserList},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'fileupload'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('img',{class:{ full: _vm.fullWidthImage },attrs:{"src":_vm.fields.field_name[fieldItem.key]},on:{"click":function($event){_vm.fullWidthImage = !_vm.fullWidthImage}}})])]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'radio'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-radio-group',{model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}},_vm._l((fieldItem.option_values),function(radioItem){return _c('v-radio',{key:radioItem,attrs:{"disabled":_vm.disabled,"label":radioItem,"value":radioItem}})}),1)],1)])]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'options'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),(fieldItem.view_type === 'reference')?_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"items":fieldItem.option_values,"item-value":"key","item-text":"value","disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1):_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-select',{attrs:{"items":fieldItem.option_values,"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'textarea'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e(),(
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'phone'
                        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticStyle:{"width":"50%","margin-top":"2%"},attrs:{"align":"left"}},[_c('label',[_vm._v(_vm._s(fieldItem.display_name[_vm.$i18n.locale]))])]),_c('div',{staticStyle:{"width":"50%"},attrs:{"align":"right"}},[_c('VuePhoneNumberInput',{attrs:{"defaultCountryCode":"MM"},model:{value:(_vm.fields.field_name[fieldItem.key]),callback:function ($$v) {_vm.$set(_vm.fields.field_name, fieldItem.key, $$v)},expression:"fields.field_name[fieldItem.key]"}})],1)]):_vm._e()]):_vm._e()])])}),0)])],1)],1)]),_c('div',{staticStyle:{"width":"40%"},attrs:{"align":"right"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"p-2"},[_c('h4',{attrs:{"align":"left"}},[_vm._v("HISTORY")]),_c('v-card',{staticClass:"mx-auto"},[_c('v-timeline',{staticClass:"pr-2",attrs:{"dense":""}},[_c('v-virtual-scroll',{attrs:{"bench":_vm.benched,"items":_vm.commentsArray,"height":"800","min-height":"50","item-height":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var item = ref.item;
return [_c('v-timeline-item',{attrs:{"color":"#2A409A","small":""}},[_c('HistoryComponent',{attrs:{"item":item}})],1)]}}])})],1)],1)],1)])])]),(_vm.showWorkFlowFieldDialog)?_c('div',[_c('WorkFlowFieldDialog',{attrs:{"fieldObject":_vm.fieldObject,"screenDefinition":_vm.screenDetails,"itemDetails":_vm.itemDetails},on:{"closeWorkFlowDialog":_vm.closeWorkFlowDialog}})],1):_vm._e()]),(_vm.showMessageDialog)?_c('div',[_c('MessageDialog',{attrs:{"displayDialog":_vm.showMessageDialog,"messageObject":_vm.errorMessageShow ? _vm.errorMessageObject : _vm.okMessageObject},on:{"closeMessageDialog":_vm.closeMessageDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }